<template>
  <Popup
    title="주문정보"
    :maxWidth="800"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickOrderDetailPopupClose')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col />
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">주문정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>주문번호</th>
          <td>{{ orderData.order_id }}</td>
        </tr>
        <tr>
          <th>상품명
            <!-- <span v-if="orderData.product_name.length > 1" class="txt_info">({{ orderData.product_name.length }})</span> -->
          </th>
          <td>
            {{ orderData.item_name }}
            <!-- <span class="txt_tbl">
              <p
                v-for="(product, index) in orderData.product_name"
                :key="`productName${index}`">{{ product }}</p>
            </span> -->
          </td>
        </tr>
        <!-- <tr>
          <th>결제수단</th>
          <td>{{ orderData.pay_method }}</td>
        </tr> -->
        <tr>
          <th>주문상태</th>
          <td :class="orderData.order_status | convertIdToColor('order_status','fw_bold tc')">{{ orderData.order_status | convertIdToText('order_status') }}</td>
        </tr>
        <tr>
          <th>취소가능금액</th>
          <td :class="{tc_red : orderData.cancelable_amount < 0}">{{ orderData.cancelable_amount | convertNumberToComma }} 원</td>
        </tr>
      </template>
    </TableView>
    <Board
      :boardData="orderDetailBorderData.boardData"
      :searchParams.sync="orderDetailBorderData.searchParams"
      :dataList="orderData.trade_list || []"
      :paginationData="orderDetailBorderData.paginationData"/>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';

export default {
  name:'OrderDetailPopup',
  components:{
    Popup,
    TableView,
    Board,
  },
  props:{
    orderData: Object,
    orderDetailBorderData: Object,
  },
  computed:{
    product_name(){
      if(!this.orderData.product_name) return;
      return this.$options.filters.arrayToEllip(this.orderData.product_name, 1);
    },
  },
}
</script>