<template>
  <Popup
    title="결제취소 진행"
    :maxWidth="800"
    closeBtnText="닫기"
    compeleteBtnText="전액취소처리"
    @onClickClose="$emit('onClickOrderCancelPopupClose')"
    @onClickComplete="$emit('onClickOrderCancelPopupComplete')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col />
      </template>
      <!-- <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">주문정보</strong>
        </div>
      </template> -->
      <template v-slot:tbl_body>
        <tr>
          <th>주문번호</th>
          <td>{{ orderData.order_id }}</td>
        </tr>
        <tr>
          <th>상품명
            <!-- <span v-if="orderData.item_name.length > 1" class="txt_info">({{ orderData.item_name.length }})</span> -->
          </th>
          <td>
            {{ orderData.item_name }}
            <!-- <span class="txt_tbl">
              <p
                v-for="(product, index) in orderData.item_name"
                :key="`productName${index}`">{{ product }}</p>
            </span> -->
          </td>
        </tr>
        <tr>
          <th>결제일시</th>
          <td>{{ orderData.transaction_at | convertDateFormat('YYYY-MM-DD') }} ({{ orderData.transaction_at | getDayToWeek }}) {{ orderData.transaction_at | dayNight }} {{ orderData.transaction_at | convertDateFormat('HH:mm:ss') }}</td>
        </tr>
        <tr>
          <th>결제금액</th>
          <td class="tc_blue fw_bold">{{ orderData.amount | convertNumberToComma }} 원</td>
        </tr>
        <!-- <tr>
          <th>메모</th>
          <td>
            <Input
              placeholder="메모를 입력해주세요"
              :value.sync="orderData.cancel_memo"/>
          </td>
        </tr> -->
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';

export default {
  name:'OrderCancelPopup',
  components:{
    Popup,
    TableView,
    Input,
  },
  props:{
    orderData: Object,
    orderDetailBorderData: Object,
  },
  computed:{
    product_name(){
      if(!this.orderData.product_name) return;
      return this.$options.filters.arrayToEllip(this.orderData.product_name, 1);
    },
  },
}
</script>