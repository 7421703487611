import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { addComma } from '@lemontree-ai/lemontree-admin-common-front/utils/stringUtils'
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import apiPath from '@/services/apiPath';

export default class OrderListViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'조회기간',
        type: 'DateRangeQuick',
        maxWidth:"300px",
        isFull:true,
        id:'dateRange',
        startId:'sdt',
        endId:'edt',
        value:[],
        quickTypeData:[
          { quickType : 'ONE_WEEK' },
          { quickType : 'ONE_MONTH' },
          { quickType : 'THREE_MONTH' },
          { quickType : 'SIX_MONTH' },
          { quickType : 'ONE_YEAR' },
        ],
        currentQuick:'ONE_WEEK'
      },
      {
        title:'주문상태',
        type: 'Radio',
        dataList:'order_status',
        isAddDataListAll: true,
        maxWidth:"300px",
        isFull:true,
        id:'stat',
        value:null
      },
      {
        title:'결제금액',
        placeholder:'입력',
        type: 'Input',
        valueType:'numberComma',
        isBlockUnValueType: true,
        maxWidth:"300px",
        isFull:true,
        id:'amt',
        value:null,
      },
      {
        title:'주문번호',
        placeholder:'주문번호로 검색',
        type: 'Input',
        maxWidth:"300px",
        isFull:true,
        id:'oid',
        value:'',
      },
    ]
    this.orderData = {
      dataList: [],
      boardData : {
        title:'조회결과',
        drawDataList: [
          {
            title:'주문상태',
            width:'86px',
            value:'order_status',
            filter:{
              name:'convertIdToText',
              value:'order_status'
            },
            badge: {
              badgeColorFilter:{
                name:'convertIdToColor',
                dataListName:'order_status'
              },
              badgeFill: false
            },
            isAlignLeft: true
          },
          {
            title:'주분번호 (복사)',
            width:'180px',
            value:'order_id',
            isAlignLeft: true,
            isEllip: true,
            emptyValueText:'-',
          },
          {
            title:'',
            width:'54px',
            value:'',
            button:{ 
              text:'복사',
              size:'xsmall',
              style:'secondary_border',
              onClickEvent: (data) => this.onClickCopy(data)
            }
          },
          {
            title:'결제·취소일시',
            width:'166px',
            value:'transaction_at',
            valueCustom:[
              {
                type: 'value',
                value:'transaction_at',
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
              },
              { 
                type: 'text',
                value: ' ('
              },
              {
                type: 'value',
                value:'transaction_at',
                filter:{
                  name:'getDayToWeek',
                },
              },
              { 
                type: 'text',
                value: ') '
              },
              {
                type: 'value',
                value:'transaction_at',
                filter:{
                  name:'convertDateFormat',
                  value:'HH:mm:ss'
                },
              },
            ],
            isAlignLeft: true,
            isMobileBlock: true,
          },
          {
            title:'상품명',
            width:'110px',
            value:'item_name',
            // filter:{
            //   name:'arrayToEllip',
            //   value:1
            // },
            isAlignLeft: true,
            isMobileBlock: true,
            isEllip: true,
            emptyValueText:'-',
          },
          {
            title:'결제금액',
            width:'',
            value:'amount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true
          },
          {
            title:'취소금액',
            width:'',
            value:'cancel_amount',
            isAlignRight: true,
            valueCustom:[
              { 
                type: 'text',
                value: '-',
                ifNot:{
                  value:'cancel_amount',
                  condition:null
                }
              },
              {
                type: 'value',
                value:'cancel_amount',
                filter:{
                  name:'convertNumberToComma',
                },
              },
            ],
            emptyValueText:'0'
          },
          {
            title:'취소가능액',
            width:'',
            value:'cancelable_amount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true
          },
          {
            title:'결제취소',
            width:'92px',
            value:'cancel_status',
            class: { 
              classNameConvert:(value,rowData)=>{
                return this.cancelTimeOut(value,rowData)
              }
             },
            buttonCondition: [
              { condition:'PAYMENT_CANCEL_POSSIBLE', text:'결제취소', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickPaymentCancel(data) },
              { condition:'PAYMENT_CANCEL_COMPLETION', text:'결제취소', size:'small', style:'secondary_border', disabled:true },
            ],
            emptyValueText:'기한만료',
            tooltip:{
              if:{
                value:'cancel_status',
                condition:'EXPIRED'
              },
              title:'결제취소가능 기간이 아닙니다',
              desc:'최초 결제일로부터 90일 내에서만 취소할 수 있습니다.<br>계좌이체 등 가맹점 자체 방식으로 환불처리해 주세요.<br>퍼핀을 통해 취소거래가 발생하지 않았으므로 수수료 보전이나 세금계산서 반영은 불가한 점 양해 바랍니다.'
            }
          },
        ],
        option:{
          isTotal: true,
          isSize: true,
          rowIdValue: 'order_id'
        },
        emptyDesc:'조회결과가 없습니다'
      },
      searchParams: {
        page:0,
        pageSize:10,
        direction: 'DESC'
      },
      paginationData: {
        totalCount:0,
        totalPage:0
      }
    };
    // 주문상세 팝업
    this.isOrderDetailPopup = false;
    this.orderDataDetail = {};
    this.orderDetailBorderData = {
      boardData:{
        title:'거래내역',
        drawDataList: [
          {
            title:'거래ID',
            width:'160px',
            value:'tid',
            class:{
              name:'fw_bold'
            },
            isEllip: true,
            isAlignLeft: true
          },
          {
            title:'거래유형',
            width:'72px',
            value:'status',
            filter:{
              name:'convertIdToText',
              value:'order_status'
            },
            isAlignLeft: true
          },
          {
            title:'결제일시',
            width:'180px',
            value:'approvedAt',
            valueCustom:[
              {
                type: 'value',
                value:'approvedAt',
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
              },
              { 
                type: 'text',
                value: '('
              },
              {
                type: 'value',
                value:'approvedAt',
                filter:{
                  name:'getDayToWeek',
                },
              },
              { 
                type: 'text',
                value: ') '
              },
              {
                type: 'value',
                value:'approvedAt',
                filter:{
                  name:'convertDateFormat',
                  value:'HH:mm:ss'
                },
              },
            ],
            isAlignLeft: true
          },
          {
            title:'이월 정산금',
            width:'100px',
            value:'requestAmount',
            filter:{
              name:'convertNumberToComma',
            },
            valueCustom:[
              {
                type: 'value',
                value:'requestAmount',
                filter:{
                  name:'convertNumberToComma'
                },
              },
              { 
                type: 'text',
                value: ' 원'
              },
            ],
            class: { 
              classNameConvert:(value,rowData)=>{
                return this.isMinus(value,rowData)
              }
            },
            isAlignLeft: true
          },
          {
            title:'메모',
            width:'',
            value:'trade_memo',
            isAlignLeft: true,
            isMobileBlock: true,
          },
        ],
        option:{
          isTotal: true,
          isSize: true,
          rowIdValue: 'tid'
        }
      },
      searchParams: {
        page:0,
        pageSize:10,
        direction: 'ASC'
      },
      paginationData: {
        totalCount:3,
        totalPage:0
      }
    }
    //결제취소 팝업
    this.isOrderCancelPopup = false;
  }
  init(){
    // this.getData();
    this.getOrderList();
  }
  getSearchParams(){
    const searchData = this.orderData.searchParams;
    this.searchDataList.forEach(item => {
      if(item.id === 'dateRange'){
        searchData[item.startId] = item.value[0];
        searchData[item.endId] = item.value[1];
      }else{
        searchData[item.id] = item.value;
      }
    });
    return makeQueryStringByObject(searchData);
  }
  onClickRow(rowId,rowData){
    // router.push({ name: 'SETTLE_SETTLE_DETAIL', params: { id: rowId, step : this.tabData.value } });
    this.orderDataDetail = rowData;
    if(rowId){
      this.getOrderDetail(rowId);
    }
  }
  isMinus(value,rowData){
    return rowData.payment_price < 0 ? 'tc_red fw_bold' : 'tc_blue fw_bold';
  }
  cancelTimeOut(value,rowData){
    return rowData.order_status === 'EXPIRED' ? 'tc_red' : '';
  }
  onClickOrderDetailPopupClose(){
    this.isOrderDetailPopup = false;
    this.orderDataDetail = {};
  }
  onSearch(){
    // this.getData();
    console.log('onSearch')
    this.getOrderList();
  }
  onClickPaymentCancel(rowData){
    this.orderDataDetail = rowData;
    this.isOrderCancelPopup = true;
  }
  onClickOrderCancelPopupClose(){
    this.isOrderCancelPopup = false;
    this.orderDataDetail = {};
  }
  onClickOrderCancelPopupComplete(){
    const orderCancel = () => {
      this.postOrderCancel()
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>결제를 취소할까요?</strong>${addComma(this.orderDataDetail.amount)}원을 구매자에게 환불합니다.`,
      closeBtnText: '취소',
      compeleteBtnText: '네, 취소처리 합니다',
      confirmCallBack: orderCancel,
    });
  }
  onClickCopy(rowData){
    window.navigator.clipboard.writeText(rowData.order_id).then(() => {
      store.dispatch('commonToast/fetchToastStart', `주문번호가 복사되었습니다`);
    });
  }

  getOrderList(){
    const query = this.getSearchParams();
    const path = `${apiPath.ORDER_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      const pagination = success.data.pagination;
      this.orderData.dataList = resultData.map(item => {
        return item.orderList;
      });
      this.orderData.paginationData.totalCount = resultData.length;
      this.orderData.paginationData.totalPage = 1;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  getOrderDetail(orderId){
    const path = `${apiPath.ORDER_DETAIL.format(orderId)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.orderDataDetail.trade_list = resultData;
      this.isOrderDetailPopup = true;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  postOrderCancel(){
    const nowDate = new Date();
    const {tid, cancelable_amount} = this.orderDataDetail
    const data = {
      body : {
        tid : tid,
        cancel_amount : cancelable_amount,
      },
      head : {
        request_at : convertDateFormat(nowDate,'YYYY-MM-DD HH:mm:ss')
      }
    }
    console.log(data)
    const path = `${apiPath.QR_CANCEL}`;
    POST_REQUEST(path,data).then(
    (success) => {
      this.isOrderCancelPopup = false;
      this.orderDataDetail = {};
      store.dispatch('commonToast/fetchToastStart', `주문의 결제금액 전액취소 처리되었습니다`);
      this.getOrderList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}