<template>
  <PageWithLayout :isBodyBg="false">
    <Search
      :searchDataList.sync="viewModel.searchDataList"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.orderData.boardData"
      :searchParams.sync="viewModel.orderData.searchParams"
      :dataList="viewModel.orderData.dataList"
      :paginationData="viewModel.orderData.paginationData"
      :emptyDesc="viewModel.orderData.boardData.emptyDesc"
      @onSearch="viewModel.onSearch()"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="결제 QR 생성하기"
          routeName="PAYMENT_PAYEMNT_REQUEST">
        </Button>
      </template>
    </Board>
    <template v-slot:popup>
      <OrderDetailPopup
        v-if="viewModel.isOrderDetailPopup"
        :orderData="viewModel.orderDataDetail"
        :orderDetailBorderData="viewModel.orderDetailBorderData"
        @onClickOrderDetailPopupClose="viewModel.onClickOrderDetailPopupClose()"/>
      <OrderCancelPopup
        v-if="viewModel.isOrderCancelPopup"
        :orderData="viewModel.orderDataDetail"
        @onClickOrderCancelPopupClose="viewModel.onClickOrderCancelPopupClose()"
        @onClickOrderCancelPopupComplete="memo => viewModel.onClickOrderCancelPopupComplete(memo)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import OrderDetailPopup from '@/views/store/order/view/popup/OrderDetailPopup'
import OrderCancelPopup from '@/views/store/order/view/popup/OrderCancelPopup'
// viewModel
import OrderListViewModel from '@/views/store/order/viewModel/OrderListViewModel'

export default {
  name:'OrderList',
  components:{
    PageWithLayout,
    Search,
    Board,
    Button,
    OrderDetailPopup,
    OrderCancelPopup
  },
  mounted(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new OrderListViewModel(),
    }
  },
}
</script>